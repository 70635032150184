// Square off the corners, at least in selects and chips.
.mat-form-field-appearance-outline .mdc-notched-outline__leading,
.mat-form-field-appearance-outline .mdc-notched-outline__trailing,
.mat-mdc-button,
.mat-mdc-icon-button,
button[mat-stroked-button],
.mat-mdc-unelevated-button,
mat-chip.mat-mdc-chip.mat-mdc-standard-chip,
mat-dialog-container,
textarea {
  border-radius: 0 !important;
}

mat-form-field {
  width: 100%;
}

// Set up icons within the input boxes
mat-form-field.input-icon-box {
  & input {
    width: 90%;
  }

  & .material-icons {
    float: right;
    margin-top: -0.5625rem;
    padding-top: 0.4125rem;
    margin-bottom: -0.625rem;
  }
}

.mat-form-field-invalid {
  color: $error;
}

// Line all checkboxes to the first line of a label.
.mdc-checkbox {
  align-self: baseline;
}

// Ensure the chips grow vertically.
mat-chip.mat-mdc-chip.mat-mdc-standard-chip {
  height: auto !important;
  padding: 4px 0;
  span {
    white-space: normal !important;
  }
}



// ----------------------------------------------------------------
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  font-weight: 600;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea), .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  caret-color: rgba(0, 0, 0, 0.87);
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {

  background-color: rgba(0, 0, 0, 0.87) !important;
  border-color: rgba(0, 0, 0, 0.87) !important;

}

.mdc-form-field>label {
  word-wrap: break-word;
  max-width: 265px;
}
