/* You can add global styles to this file, and also import other style files */
@import '../../../libs/ui-cdk/src/lib/scss/styles.scss';

// Prevents Matrial Switch component from having a red background due to the footer css code
button.mdc-switch {
  &:focus,
  &:hover {
    background-color: transparent;
  }
}

* + * {
  margin-top: unset !important;
}

// Header

@media (min-width: 87.5em) {
  .primary-nav a {
    font-size: 1.1875rem;
  }
  .utility-nav {
    padding: 0.875rem 0;
  }
}

.utility-nav {
  padding: 0.8125rem 0;
  [href^="https://"]:not(.internal):not(.img-pop):not(.vid-pop)
  {
    padding-right: 0;
  }
}

.primary-nav {
  [href^="https://"]:not(.internal):not(.img-pop):not(.vid-pop)
  {
    padding-right: 0;
  }

  a {
    font-family: Gore Gustan, Arial, sans-serif !important;
    font-style: normal;
    padding: 0.40625rem 0;
    font-size: 0.9375rem;
    letter-spacing: 0.035em;
    font-weight: 700;
    text-transform: uppercase;
  }
}

// Footer

footer .smallest {
  margin-top: 16px !important;
  // Configure the position of the modals on smaller screens
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 100%;
  }
}

// Footer MPD
.site-footer__inner {
  padding: 35px 11.5px !important;
  .footer-nav li {
    margin-top: 9px !important;
  }
}


// Material related styles

.mat-mdc-outlined-button[mat-stroked-button]:hover {
  color: $primary-white;
}
