/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import './abstracts/variables';
@import './base/custom-theme.scss';
@import './base/reset-mat.scss';
@import './abstracts/fonts';

html,
body {
  font-size: 16px;
  height: 100%;
  color: $black-heavy-shade;
}

html,
body,
textarea {
  font-family: 'Noto Sans', Roboto, 'Helvetica Neue', sans-serif !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

body {
  margin: 0;
}

body p {
  font-family: 'Noto Sans', Roboto, 'Helvetica Neue', sans-serif;
}

@media only screen and (max-width: $sm-max) {
  .message-window .body.message-box {
    padding-left: 0.875rem;
  }

  .vertical-wrapper.wide-only {
    justify-content: center;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;

    button {
      margin-top: 0.375rem;
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 570px) {
  .cdk-overlay-pane {
    max-width: 100% !important;
  }
}

// TODO: Check if exists
a,
.red {
  color: mat.get-color-from-palette($cpc-web-primary);
  text-decoration: none;
}

// Give a simple set of classes to make things align.
.left {
  float: left;
}

.right {
  float: right;
}

// Force lower case text, just in case
.lower {
  text-transform: initial !important;
}

.bold {
  font-weight: bold !important;
}

// Line all checkboxes to the first line of a label.
.submit-row {
  margin-bottom: 1.5rem;
  min-height: 2.625rem;
}

// Give a wrapper to perform vertical centering
.vertical-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

// Color the icons to the accent
.material-icons {
  color: $dark-blue;

  // Set up to lower the info (Personal and Company) icon inline with the h2
  &.info {
    cursor: pointer;
    margin-top: 0.25rem;
  }
}

.ng-invalid {
  color: $error;
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.subtitle,
.button,
div.group label {
  font-weight: bold !important;
}

div.group label {
  font-size: 1rem;
  text-transform: uppercase;
}

p,
.body,
.message-window .message {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

h1 {
  font-size: 2.125rem;
  line-height: 1.29;
  letter-spacing: normal;
  margin: 0 0 16px;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: 0.18px;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.25;
  letter-spacing: 0.15px;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.33;
  letter-spacing: 0.14px;
}

.subtitle {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
}

.small-body {
  font-size: 0.875rem;
  font-weight: 300;
}

.button {
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 1.43px;
  text-align: center;
}

.required-star {
  color: $primary-red;
}

// Configure the shared message windows
.message-window {
  align-items: center;
  border-width: 0.0625rem;
  border-top-width: 0.1875rem;
  border-style: solid;
  display: flex;
  width: 100%;
  min-height: 3.75rem;

  & .material-icons {
    margin-left: 0.875rem;
    margin-right: 1.125rem;
  }

  & div.message-box {
    margin: 0.875rem 1rem 0.875rem 0.5rem !important;
  }

  & .message {
    display: inline-block;
    width: 100%;
  }
}

html.geolocation {
  box-sizing: border-box;
  background: unset;
}

@media only screen and (max-width: $sm-max) {
  div.cdk-global-overlay-wrapper {
    align-items: center !important;
  }

  div.cdk-overlay-pane {
    margin: 0 auto !important;
  }
}

// Need to ensure that iOS Safari mobile browsers don't zoom on the inputs
// https://stackoverflow.com/a/16255670/1195056
@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

.error {
  color: $error;
}

// Fix submit button

.mdc-button {
  letter-spacing: normal;
  white-space: nowrap;
}

.visually-hidden,
.visuallyhidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
