// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '../abstracts/variables';
@import '../abstracts/palette';


// Plus imports for other components in your app.

$custom-typography: mat.define-typography-config($font-family: '"Noto Sans", Roboto, "Helvetica Neue", sans-serif',);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core($custom-typography);
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cpc-web-primary: mat.define-palette($md-primary-red);
$cpc-web-accent: mat.define-palette($md-accent-blue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cpc-web-warn: mat.define-palette($md-error);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cpc-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $cpc-web-primary,
      accent: $cpc-web-accent,
      warn: $cpc-web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-typographies($custom-typography);
@include mat.all-component-themes($cpc-web-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
