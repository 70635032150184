// ================================================================
// ========================== Gore Gustan =========================
// ================================================================
@font-face {
  font-family: "Gore Gustan";
  src: url("../../../assets/fonts/gore-gustan/GoreGustan-Book.eot");
  src: url("../../../assets/fonts/gore-gustan/GoreGustan-Book.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gore Gustan";
  src: url("../../../assets/fonts/gore-gustan/GoreGustan-Medium.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gore Gustan";
  src: url("../../../assets/fonts/gore-gustan/GoreGustan-Bold.eot");
  src: url("../../../assets/fonts/gore-gustan/GoreGustan-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
