$primary-black: #000000;
$dark-gray: #5b6770;
$medium-gray: #a2aaad;
$light-gray: #eceeef;
$pale-gray: #f5f6f7;
$primary-white: #ffffff;

$dark-blue: #009cde;
$light-blue: rgba(62, 177, 200, 0.3);

$primary-red: #f00f00;
$error: #b00020;

$black-heavy-shade: rgba(0, 0, 0, 0.87);
$black-medium-shade: rgba(0, 0, 0, 0.74);

$primary-green: #00b700;

$xs-max: 576px;
$sm-min: 577px;
$sm-max: 768px;
$md-min: 769px;
$md-max: 992px;
$lg-min: 993px;
$lg-max: 1200px;
$xl-min: 1201px;
